module.exports = {
  development: {
    siteUrl: "http://localhost:3001",
    api: {
      baseUrl: "https://localhost:44320",
      lbcOAkey: "3cf4f9cb1bb34f60a03f1a4b0f74208e",
      generatePODUrl:
        "https://lbcapigateway.lbcapps.com/lbcwaybill/v1/api/POD/GeneratePOD",
      generatePODLbcOAkey: "e08a3ba2c3ab44fb98c4975202926820",
      // lbccommonrefv2Url: "https://lbcapigateway.lbcapps.com/lbccommonref2dev",
      // lbccommonrefv2LbcOAkey: "8af9a78d264c4c4eb4fa9d4d22ee7dc4",
      lbccommonrefv2Url:
        "https://lbcapigateway.lbcapps.com/lbccommonreference/v2/v2",
      lbccommonrefv2LbcOAkey: "e8432716e5d3424bb2322a43fd4e470a",
      lexaapiUrl: "https://lbcapigateway.lbcapps.com/lexaapidev/v1/api",
      lexaapiToken:
        "O8VpRnC2bIwe74mKssl11c0a1kz27aDCvIci4HIA+GOZKffDQBDkj0Y4kPodJhyQaXBGCbFJcU1CQZFDSyXPIBni",
      lexaapiLBCOAkey: "5521e53e83484bd091ad2c7f01fdb296",
      trackingUrl: "https://lbcapigateway.lbcapps.com/lbctrackingapi2/v2",
      trackingLBCOAkey: "d318501d4a18473f8e819c21048b63c9",
    },
    supportCenterEmailCorp: "Sales_Support@lbcexpress.com",
    supportCenterEmailRetail: "mbparreno@lbcexpress.com",
  },
  production: {
    siteUrl: "https://corp-dash-web.lbcapps.com",
    api: {
      baseUrl: "https://dwebsrv03.lbcapps.com/corp-dash-api",
      lbcOAkey: "3cf4f9cb1bb34f60a03f1a4b0f74208e",
      generatePODUrl:
        "https://lbcapigateway.lbcapps.com/lbcwaybill/v1/api/POD/GeneratePOD",
      generatePODLbcOAkey: "e08a3ba2c3ab44fb98c4975202926820",
      // lbccommonrefv2Url: "https://lbcapigateway.lbcapps.com/lbccommonref2dev",
      // lbccommonrefv2LbcOAkey: "8af9a78d264c4c4eb4fa9d4d22ee7dc4",
      lbccommonrefv2Url:
        "https://lbcapigateway.lbcapps.com/lbccommonreference/v2/v2",
      lbccommonrefv2LbcOAkey: "e8432716e5d3424bb2322a43fd4e470a",
      lexaapiUrl: "https://34.160.218.33.nip.io/lbconlinedev",
      // lexaapiUrl: "https://lbcapigateway.lbcapps.com/lexaapidev/v1/api",
      lexaapiToken:
        "O8VpRnC2bIwe74mKssl11c0a1kz27aDCvIci4HIA+GOZKffDQBDkj0Y4kPodJhyQaXBGCbFJcU1CQZFDSyXPIBni",
      lexaapiLBCOAkey: "5521e53e83484bd091ad2c7f01fdb296",
      trackingUrl: "https://lbcapigateway.lbcapps.com/lbctrackingapi2/v2",
      trackingLBCOAkey: "d318501d4a18473f8e819c21048b63c9",
    },
    supportCenterEmailCorp: "Sales_Support@lbcexpress.com",
    supportCenterEmailRetail: "mbparreno@lbcexpress.com",
  },
  uat: {
    siteUrl: "https://corporate.lbcexpress.com",
    api: {
      baseUrl: "https://lbcapigateway.lbcapps.com/lbccorporateapi/v1",
      lbcOAkey: "7c93be778e864904b48a8e2e37585b81",
      generatePODUrl:
        "https://lbcapigateway.lbcapps.com/lbcwaybill/v1/api/POD/GeneratePOD",
      generatePODLbcOAkey: "e08a3ba2c3ab44fb98c4975202926820",
      lbccommonrefv2Url:
        "https://lbcapigateway.lbcapps.com/lbccommonreference/v2/v2",
      lbccommonrefv2LbcOAkey: "e8432716e5d3424bb2322a43fd4e470a",
      lexaapiUrl: "https://34.160.218.33.nip.io/lbconline",
      // lexaapiUrl: "https://lbcapigateway.lbcapps.com/lexaapi/lexav1/api",
      lexaapiToken:
        "O8VpRnC2bIwe74mKssl11c0a1kz27aDCvIci4HIA+GOZKffDQBDkj0Y4kPodJhyQaXBGCbFJcU1CQZFDSyXPIBni",
      lexaapiLBCOAkey: "d1ca28c5933f41638f57cc81c0c24bca",
      trackingUrl: "https://lbcapigateway.lbcapps.com/lbctrackingapi2/v2",
      trackingLBCOAkey: "d318501d4a18473f8e819c21048b63c9",
    },
    supportCenterEmailCorp: "Sales_Support@lbcexpress.com",
    supportCenterEmailRetail: "mbparreno@lbcexpress.com",
  },
};
